var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.DetailItems.name,
        params: {
          id: _vm.item.id
        }
      }
    }
  }, [_vm._v(" Detail Item ")])], 1)], 1), _c('b-card', {
    attrs: {
      "header": 'Preview Page - ' + _vm.item.name,
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, _vm._l(_vm.item.preview_page, function (page) {
    var _vm$file$page, _vm$file$page2, _vm$file$page3;
    return _c('b-row', {
      key: page,
      staticClass: "p-2"
    }, [_c('b-col', {
      attrs: {
        "md": "3 text-center"
      }
    }, [_c('a', {
      attrs: {
        "href": _vm.srcPreview(page),
        "target": "_blank"
      }
    }, [_c('b-card-img', {
      staticClass: "img-preview shadow rounded",
      staticStyle: {
        "max-width": "200px"
      },
      attrs: {
        "src": _vm.srcPreview(page),
        "alt": 'preview-' + page
      }
    })], 1)]), _c('b-col', {
      attrs: {
        "md": "8",
        "lg": "6"
      }
    }, [_c('b-form-group', {
      staticClass: "font-weight-bold",
      attrs: {
        "label": "Replace Preview :"
      }
    }, [_c('b-form-file', {
      attrs: {
        "state": Boolean(((_vm$file$page = _vm.file[page]) === null || _vm$file$page === void 0 ? void 0 : _vm$file$page.size) < Math.pow( 1000, 2 )),
        "placeholder": "Choose a file or drop it here...",
        "accept": ".jpg",
        "drop-placeholder": "Drop file here..."
      },
      model: {
        value: _vm.file[page],
        callback: function ($$v) {
          _vm.$set(_vm.file, page, $$v);
        },
        expression: "file[page]"
      }
    }), _vm.file[page] ? _c('span', {
      staticClass: "float-left"
    }, [_vm._v(" " + _vm._s(_vm._f("formatKB")(_vm.file[page].size)))]) : _vm._e(), Boolean(((_vm$file$page2 = _vm.file[page]) === null || _vm$file$page2 === void 0 ? void 0 : _vm$file$page2.size) > Math.pow( 1000, 2 )) ? _c('span', {
      staticClass: "font-weight-light text-danger pl-2"
    }, [_vm._v("max file 1 MB")]) : _vm._e(), _c('span', {
      staticClass: "float-right"
    }, [_c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm",
        "disabled": Boolean(((_vm$file$page3 = _vm.file[page]) === null || _vm$file$page3 === void 0 ? void 0 : _vm$file$page3.size) > Math.pow( 1000, 2 ))
      },
      on: {
        "click": function ($event) {
          return _vm.onUploadPreview(page);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-upload"
    }), _vm._v(" upload ")])], 1)], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('hr')])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }