<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: DetailItems.name, params: { id: item.id } }">
          Detail Item
        </router-link>
      </b-col>
    </b-row>
    <b-card :header="'Preview Page - ' + item.name" header-bg-variant="primary" header-tag="h5"
      header-class="font-weight-bold">
      <b-row class="p-2" v-for="page in item.preview_page" :key="page">
        <b-col md="3 text-center">
          <a :href="srcPreview(page)" target="_blank">
            <b-card-img :src="srcPreview(page)" :alt="'preview-' + page " style="max-width: 200px; "
              class="img-preview shadow rounded">
            </b-card-img>
          </a>
        </b-col>
        <b-col md="8" lg="6">
          <b-form-group label="Replace Preview :" class="font-weight-bold">
            <b-form-file
              v-model="file[page]"
              :state="Boolean(file[page]?.size < 1000**2)"
              placeholder="Choose a file or drop it here..."
              accept=".jpg"
              drop-placeholder="Drop file here...">
            </b-form-file>
            <span class="float-left" v-if="file[page]"> {{  file[page].size | formatKB }}</span>
            <span class="font-weight-light text-danger pl-2"
              v-if="Boolean(file[page]?.size > 1000**2)"
            >max file 1 MB</span>
            <span class="float-right">
              <b-button
                variant="primary"
                size="sm"
                @click="onUploadPreview(page)"
                :disabled="Boolean(file[page]?.size > 1000**2)"
              >
                <i class="fa fa-upload"></i> upload
              </b-button>
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DetailItems } from '../../../router/content';
import constant from "../../../store/constant";

export default {
  name: "ebookPreveiewEdit",
  data() {
    return {
      DetailItems,
      basePathPreview: constant.URL_STATIC.EBOOK_PREVIEW,
      file: {},
      now: +new Date(),
    }
  },
  mounted() {
    let id = this.$route.params.id
    this.actionGetItem(id);
    this.item_id = id;
  },
  computed: {
    ...mapState({
      item: (state) => state.ebooks.item,
      isLoading: (state) => state.ebooks.isLoading,
    }),
  },
  methods: {
    ...mapActions('ebooks', ['fetchEbookById']),
    ...mapActions('items', ['uploadPreview']),
    actionGetItem(id) {
      let payload = {
        id: id
      };
      this.fetchEbookById(payload)
    },
    srcPreview(page) {
      return this.basePathPreview +
        this.item.brand_id + '/' +
        this.item.id + '/' +
        page + '.jpg'+ '?' + this.now;
    },
    async onUploadPreview(page) {
      console.log(this.file[page]);

      this.$store.commit(`ebooks/setLoading`, true);
      const payload = {
        id: this.item_id,
        file_preview: this.file[page],
        page,
      };
      const response = await this.uploadPreview(payload);
      if (response.status == 200) {
        this.messageAlert('success', `upload success`)
        this.actionGetItem(this.item_id);
        this.file = {};
      }
      else {
        this.$store.commit(`ebooks/setLoading`, false)
        this.messageAlert('error', `${response.status ?? '-'} : ${response.statusText}`)
      }
      this.now = +new Date();
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
  filters: {
    formatKB(val) {
      return `${(val / 1000).toFixed(2)} KB`;
    },
  },
};
</script>

<style lang="css" scoped>
.img-preview {
  min-width: 200px;
  min-height: auto;
  max-width: 200px;
  max-height: auto;
}
</style>